import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import apiClient from "../../../axios";
import SuccessProcess from '../SuccessProcess';
import ErrorNotification from '../ErrorNotification';
import LoginNotification from "../LoginNotification";

const RolNotification = ({ message, isVisible, onClose, user, onRoleUpdate }) => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [message2, setMessage2] = useState('');
    const [roleId, setRoleId] = useState(user.roleId || '');
    const [rolList, setRolList] = useState([]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await apiClient.get(`/admin/roles`);
                if (Array.isArray(response.data)) {
                    setRolList(response.data);
                } else {
                    setRolList([response.data]);
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };

        fetchRoles();
    }, []);

    const handleConfirm = async () => {
        try {
            await apiClient.post(`/admin/staff/${user.code}/role/${roleId}`);
            setIsErrorVisible(false);
            setSuccessVisible(true);
            setMessage2('Rol actualizado exitosamente ');
            if (onRoleUpdate) {
                onRoleUpdate();
            }
        } catch (error) {
            setIsErrorVisible(true);
            setMessage2('Ocurrió un error al actualizar el rol.');
            if (error.response.data.error === "Unauthorized") {
                setIsInfoVisible(true);
            }
        }
    };

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon-noti2" />
                    </div>
                    <div className="message-container-info">
                        <h4>Cambio de Rol</h4>
                        <p>{message}</p>
                    </div>
                    <div className="basic-info-form-group">
                        <label>Rol</label>
                        <select
                            value={roleId}
                            onChange={(e) => setRoleId(e.target.value)}
                        >
                            <option value="">Seleccione un Rol</option>
                            {rolList.map((rol) => (
                                <option key={rol.id} value={rol.id}>
                                    {rol.description}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    <SuccessProcess
                        message={message2}
                        isVisible={successVisible}
                        onClose={handleCloseSuccess}
                        redirectOnOk = {false}
                    />
                    <ErrorNotification
                        message="Ups! Ocurrio un Problema"
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuele a iniciar sesión"
                        isVisible={isInfoVisible}
                        onClose={() => setIsInfoVisible(false)}
                    />
                </div>
            </div >
        )
    );
};

export default RolNotification;

