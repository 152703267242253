import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgFenix from '../../../src/static/miniAcl.png';
import { faUser, faHome, faCubes, faStore, faUserGear } from '@fortawesome/free-solid-svg-icons'; // Importa los iconos
import './sidebar.css';

const Sidebar = () => {
  const role = localStorage.getItem('role');

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };
  return (
    <div className="sidebar">
      <div className="logo">
        <img src={imgFenix} alt="Logo" />
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/Home" className="menu-item">
              <div
                className="icon-container"
              >
                <FontAwesomeIcon icon={faHome} className="icon" />
              </div>
              <div className="menu-text-arrow">
                <span className="menu-text">Inicio</span>
              </div>
            </Link>
          </li>
          <li>
            {hasPermission('PRODUCT_LIST') && (
              <Link to="/Module" className="menu-item">
                <div
                  className="icon-container"
                >
                  <FontAwesomeIcon icon={faCubes} className="icon" />
                </div>
                <div className="menu-text-arrow">
                  <span className="menu-text">Módulos</span>
                </div>
              </Link>
            )}
          </li>
          <li>
            {hasPermission('CUSTOMER_LIST') && (
              <Link to="/Customers" className="menu-item">
                <div
                  className="icon-container"
                >
                  <FontAwesomeIcon icon={faUser} className="icon" />
                </div>
                <div className="menu-text-arrow">
                  <span className="menu-text">Clientes</span>
                </div>
              </Link>
            )}
          </li>
          <li>
            {(role === 'CHANEL_ADMIN' || role === 'SUPER_ADMIN' || role === 'ADMIN') && (
              <Link to="/PurchaseList" className="menu-item">
                <div
                  className="icon-container"
                >
                  <FontAwesomeIcon icon={faStore} className="icon" />
                </div>
                <div className="menu-text-arrow">
                  <span className="menu-text">Ordenes</span>
                </div>
              </Link>
            )}
          </li>
          <li>
            {role === 'SUPER_ADMIN' && (
              <Link to="/Management" className="menu-item">
                <div
                  className="icon-container"
                >
                  <FontAwesomeIcon icon={faUserGear} className="icon" />
                </div>
                <div className="menu-text-arrow">
                  <span className="menu-text">Administración</span>
                </div>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
