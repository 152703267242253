import React, { useState } from 'react';
import SuccessNotification from '../Notifications/SuccessNotification';
import ErrorNotification from '../Notifications/ErrorNotification';
import LoginNotification from "../Notifications/LoginNotification";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck} from '@fortawesome/free-solid-svg-icons';

import apiClient from "../../axios";

const OrderModal = ({ data, id, isVisible, onClose }) => {
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
   
    const handleConfirm = async () => {
        try {
            const response = await apiClient.post(`/orders/${id}/purchase`);
            if (response.status === 201) {
                setIsErrorVisible(false);
                setIsSuccessVisible(true);

            }

        } catch (error) {
            setIsErrorVisible(true);
            setIsSuccessVisible(false);
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 4000);
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }
        }
    };

    return (
        isVisible && (

            <div className="basic-info-form-container" >
                <h3 className="basic-info-form-title">Información de la Orden</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label >Cliente</label>
                        <input
                            type="text"
                            placeholder="Cliente"
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label >Servidor</label>
                        <input
                            type="text"
                            placeholder="Servidor"
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label > Nro. Identificador</label>
                        <input
                            type="text"
                            placeholder=" Nro. Identificador"
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label > Producto</label>
                        <input
                            type="text"
                            placeholder=" Producto"
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label > Tipo Contrato</label>
                        <input
                            type="text"
                            placeholder=" Tipo Contrato"
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label > Observación</label>
                        <input
                            type="text"
                            placeholder="Observación"
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label > Distribuidor</label>
                        <input
                            type="text"
                            placeholder="Distribuidor"
                            readOnly
                        />
                    </div>
                </div>
                <div className="basic-form-footer">

                    <table style={{ borderCollapse: 'collapse', width: '30%' }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: '5px', textAlign: 'left', fontWeight: 'boldeer' }}>
                                    <label>Total:</label>
                                </td>
                                <td style={{ padding: '5px', textAlign: 'right' }}>
                                    <span>$0.00</span> {/* Ejemplo */}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={onClose}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button className="basic-custom-button" onClick={handleConfirm}>
                        <FontAwesomeIcon icon={faCheck} className="basic-shortcut-icon" />
                        Confirmar
                    </button>
                </div>
                <SuccessNotification
                    message={"Compra realizado Correctamente"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
                <ErrorNotification
                    message={"Error al realizar la Compra"}
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isLoginVisible}
                    onClose={() => setIsLoginVisible(false)}
                />
            </div>

        )

    );
};

export default OrderModal;