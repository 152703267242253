import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import DebtDetail from '../DebtDetail';

const DebtModal = ({ message, isVisible, onClose, code }) => {

    const [modalDeatil, setDetail] = useState(false);

    const handleViewDetail = () => {
        setDetail(true);
    };

    const handleCloseSuccess = () => {
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon-noti2" />
                    </div>
                    <div className="message-container-info">
                        <h4>No se puede crear Contarto</h4>
                        <p>{message}</p>
                    </div>
                    <div className="button-container-info">
                        <button className="ok-btn-info" onClick={onClose}>
                            OK
                        </button>
                        <button className="ok-btn-info" onClick={handleViewDetail}>
                            Ver Detalles
                        </button>
                    </div>

                </div>
                {modalDeatil && (
                    <div className="modal-overlay-edit">
                        <div className="modal-content-edit">
                            <DebtDetail code={code} closeModal={handleCloseSuccess} />
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default DebtModal;
