import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const LoginNotification = ({ message, isVisible, onClose, onLogout }) => {
    
    const handleConfirm = async () => {
        try {
            localStorage.clear();
            onLogout();
            onClose();
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faRightFromBracket} className="icon-noti2" />
                    </div>
                    <div className="message-container-info">
                        <h4>Lo sentimos, la sesión ha expirado</h4>
                        <p>{message}</p>
                    </div>
                    <div className="button-container-info">
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default LoginNotification;
