import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const PermissionFrom = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [process, setProcess] = useState("");
    const [action, setAction] = useState("");
    const [description, setDescription] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            const fetchProduct = async () => {
                try {
                    const response = await apiClient.get(`/admin/permissions/${id}`);
                    setProcess(response.data.process);
                    setAction(response.data.action);
                    setDescription(response.data.description);
                } catch (error) {
                    console.error("Error al cargar el permiso", error);
                }
            };
            fetchProduct();
        }
    }, [id]);

    const validateFields = () => {
        let validationErrors = {};
        const onlyLetters = /^[a-zA-Z]+$/;
        if (!process) {
            validationErrors.process = "El Campo es Obligatorio";
        } else if (!onlyLetters.test(process)) {
            validationErrors.process = "Solo se permiten letras";
        }
        if (!action) validationErrors.action = "El Campo es Obligatorio";
        if (!description) validationErrors.description = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    description
                };
                response = await apiClient.patch(
                    `/admin/permissions/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/PermissionsList");
                }
            } else {
                const payload = {
                    process,
                    action,
                    description
                };
                response = await apiClient.post("/admin/permissions", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/PermissionsList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("PERMISSION_ALREADY_EXIST")) {
                setErrorMessage("El Permiso ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear el Permiso");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Permisos" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/PermissionsList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del Permiso</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.process ? "red" : "inherit" }}>
                            Proceso
                        </label>
                        <input
                            type="text"
                            placeholder="Proceso"
                            value={process}
                            onChange={(e) => setProcess(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.process && <p className="error-message">{errors.process}</p>}
                    </div>

                    <div className="basic-info-form-group">
                        <label style={{ color: errors.action ? "red" : "inherit" }}>
                            Acción
                        </label>
                        <input
                            type="text"
                            placeholder="Acción"
                            value={action}
                            onChange={(e) => setAction(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.action && <p className="error-message">{errors.action}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.description ? "red" : "inherit" }}>
                            Descripción
                        </label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        {errors.description && <p className="error-message">{errors.description}</p>}
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/PermissionsList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "El Permiso se ha Actualizado Correctamente."
                        : "El Permiso se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default PermissionFrom;
