import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import './NotificationContract.css';

const NotificationContract = ({ message, isVisible, onClose, reg1 }) => {
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert("Copiado al portapapeles");
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay-contract">
                <div className="info-notification-inf-contract">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleCheck} className="icon-noti3" />
                    </div>
                    <div className="message-container-info">
                        <h4>Guardado Correctamente</h4>
                        <p>{message}</p>
                        {/* Muestra reg1 y reg2 uno debajo del otro */}
                        <div className="reg-container">
                            {reg1 && (
                                <div className="reg-item">
                                    <span>Registro: <br></br> {reg1}</span>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="basic-shortcut-icon"
                                        onClick={() => handleCopy(reg1)}
                                        title="Copiar Registro 1"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-container-info">
                        <button className="ok-btn-info" onClick={onClose}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default NotificationContract;
