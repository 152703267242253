import React from 'react';
import './Loader.css'; // Asegúrate de tener el archivo CSS con los estilos anteriores
import fenixgif from './FENIX.gif'; // Importa el GIF desde la misma carpeta

const Loader = () => {
  return (
    <div className="loader-overlay">
      <img src={fenixgif} alt="Loading..." className="loader-gif" />
    </div>
  );
};

export default Loader;
