import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark,
    faEyeSlash,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const UsersForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [description, setDescription] = useState("");
    const [confirmarClave, setConfirmarClave] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            const fetchProduct = async () => {
                try {
                    const response = await apiClient.get(`/users/${id}`);
                    setUserName(response.data.userName);
                    setEmail(response.data.email);
                    setDescription(response.data.description);
                } catch (error) {
                    console.error("Error al cargar el Usuario", error);
                }
            };
            fetchProduct();
        }
    }, [id]);

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const validateEmail = (email) => {
        // Expresión regular para validar el formato de email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email); // Solo valida el formato del correo
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Validar el email
        if (!validateEmail(value)) {
            setErrors({
                email: 'Email inválido. Debe contener "@" y un dominio válido.',
            });
        } else {
            setErrors({});
        }
    };

    const validateFields = () => {
        let validationErrors = {};
        const onlyLetters = /^[a-zA-Z]+$/;
        if (!userName) {
            validationErrors.userName = "El Campo es Obligatorio";
        } else if (!onlyLetters.test(userName)) {
            validationErrors.userName = "Solo se permiten letras";
        }
        if (!email) validationErrors.email = "El Campo es Obligatorio";
        if (!password) validationErrors.password = "El Campo es Obligatorio";
        if (password !== confirmarClave) {
            validationErrors.confirmarClave = "Las claves no coinciden";
        }
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    password,
                    description
                };
                response = await apiClient.patch(
                    `/users/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/UsersList");
                }
            } else {
                const payload = {
                    userName,
                    password,
                    email,
                    description
                };
                response = await apiClient.post("/users", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/UsersList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("USERNAME_ALREADY_EXIST")) {
                setErrorMessage("El Usario ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear el Usario");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Usuarios" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/UsersList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del Usario</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.userName ? "red" : "inherit" }}>
                            Nombre
                        </label>
                        <input
                            type="text"
                            placeholder="Nombre"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.userName && <p className="error-message">{errors.userName}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label>
                            Descripción
                        </label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.email ? "red" : "inherit" }}>
                            {" "}
                            Email
                        </label>
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={handleChange}
                            readOnly={!!id}
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.password ? "red" : "inherit" }}>
                            Clave
                        </label>
                        <div style={{ position: "relative", width: "99%" }}>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Clave"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="password-toggle-icon"
                                onClick={toggleShowPassword}
                            />

                        </div>
                        {errors.password && <p className="error-message">{errors.password}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.confirmarClave ? "red" : "inherit" }}>
                            Confirmar Clave
                        </label>
                        <div style={{ position: "relative", width: "99%" }}>
                            <input
                                type={"password"}
                                placeholder="Confirmar Clave"
                                value={confirmarClave}
                                onChange={(e) => setConfirmarClave(e.target.value)}
                            />
                        </div>
                        {errors.confirmarClave && <p className="error-message">{errors.confirmarClave}</p>}
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/UsersList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "El Usuario se ha Actualizado Correctamente."
                        : "El Usuario se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default UsersForm;
