import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Loader from "../Loader";
import ModulesModal from "../ModulesModal";
import Pagination from "../Table/pagination";

const TablePurchase = ({
    title,
    rows = [],
    columns = [],
    renderRow,
    icon,
    currentPage,
    totalItems,
    itemsPerPage,
    onPageChange,
    onRowClick,
    selectedRow,
    onRefresh,
    loading,
    selectedModules,
    setSelectedModules,
    onMergeRows,
}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Usamos useCallback para memorizar mergeDataWithSelectedModules
    const mergeDataWithSelectedModules = useCallback(() => {
        const updatedData = [...rows];
        selectedModules.forEach(selected => {
            const index = updatedData.findIndex(item => item.moduleId === selected.moduleId);
            if (index !== -1) {
                updatedData[index] = selected;
            } else {
                updatedData.push(selected);
            }
        });

        return updatedData;
    }, [rows, selectedModules]); // Añadimos rows y selectedModules como dependencias

    const allRows = useMemo(() => mergeDataWithSelectedModules(), [mergeDataWithSelectedModules]);

    useEffect(() => {
        if (onMergeRows) {
            onMergeRows(allRows);
        }
    }, [allRows, onMergeRows]);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleAddModules = (newModules) => {
        setSelectedModules(newModules);
        closeModal();
    };

    return (
        <div className="sales-table-container2">
            <div className="sales-table-header2">
                <div className="header-left2">
                    <div className="icon-container-table">
                        <FontAwesomeIcon icon={icon} className="icon" />
                    </div>
                    <FontAwesomeIcon
                        icon={faSync}
                        className="shortcut-icon-actually"
                        onClick={onRefresh}
                        style={{ cursor: 'pointer' }}
                    />
                    <h3>{title}</h3>
                </div>
                <div className="header-right">
                    <button
                        className="basic-custom-button"
                        onClick={openModal}
                    >
                        AGREGAR Módulos EXTRAS
                    </button>
                </div>
            </div>
            <table className="sales-table">
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>{col.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={columns.length} className="loader-container">
                                <Loader />
                            </td>
                        </tr>
                    ) : allRows.length > 0 ? (
                        allRows.map((item, index) => (
                            <tr
                                key={item.id || index}
                                onClick={() => onRowClick && onRowClick(item)}
                                className={item === selectedRow ? "selected-row" : ""}
                            >
                                {renderRow(item, index)}
                                {columns.map((col) => col.render && <td key={col.key}>{col.render(item)}</td>)}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={columns.length} className="no-data">No existen datos</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {isModalOpen && (
                <ModulesModal
                    isVisible={isModalOpen}
                    onClose={() => closeModal()}
                    onAddModules={handleAddModules}
                    initialSelectedModules={selectedModules} 
                />
            )}
            {!loading && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );
};

export default TablePurchase;
