import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import './SuccessProcess.css';

const SuccessProcess = ({ message, isVisible, onClose, redirectOnOk, pageNavigate }) => {

    const navigate = useNavigate(); 

    const handleOkClick = () => {
        if (redirectOnOk) {
            navigate(pageNavigate); 
        } else {
            onClose(); 
        }
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleCheck} className="icon-noti3" />
                    </div>
                    <div className="message-container-info">
                        <h4>Guardado Correctamente</h4>
                        <p>{message}</p>
                    </div>
                    <div className="button-container-info">
                        <button className="ok-btn-info" onClick={handleOkClick }>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default SuccessProcess;
