import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-datepicker/dist/react-datepicker.css";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import apiClient from "../../axios";
import SuccessNotification from '../Notifications/SuccessNotification';
import ErrorNotification from '../Notifications/ErrorNotification';
import Loader from "../../components/Loader";

const ApproveModal = ({ message, isVisible, onClose, id }) => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleConfirm = async () => {

        try {
            setLoading(true);
            const response = await apiClient.post(`/orders/${id}/approve`);
            if (response.data.statusCode === 200) {
                navigate("/PurchaseList");
            }
        } catch (error) {
            setIsErrorVisible(true);
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 4000);
        }
        finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon-noti2" />
                    </div>
                    <div className="message-container-info">
                        <h4>Información</h4>
                        <p>{message}</p>
                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    {loading && <Loader />}
                    <SuccessNotification
                        message="Orden aprovada exitosamente."
                        isVisible={successVisible}
                        onClose={() => setSuccessVisible(false)}
                    />
                    <ErrorNotification
                        message="Ups! Ocurrio un Problema"
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                </div>
            </div>
        )
    );
};

export default ApproveModal;
