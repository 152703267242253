import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";
import Loader from "../../components/Loader";

const CompaniesForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { numCont } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ruc, setRuc] = useState("");
    const [sequence, setSequence] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            const fetchCompanie = async () => {
                try {
                    setLoading(true);
                    const response = await apiClient.get(`/contracts/${numCont}/companies/${id}`);
                    setName(response.data.nombre);
                    setDescription(response.data.descripcion);
                    setRuc(response.data.ruc);
                    setSequence(response.data.sequence);
                } catch (error) {
                    console.error("Error al cargar la Compania", error);
                }finally{
                    setLoading(false);
                }
            };
            fetchCompanie();
        }
    }, [id, numCont]);


    const validateFields = () => {
        let validationErrors = {};
        if (!name) validationErrors.name = "El Campo es Obligatorio";
        if (!description) validationErrors.description = "El Campo es Obligatorio";
        if (!ruc) validationErrors.ruc = "El Campo es Obligatorio";
        if (!sequence) validationErrors.sequence = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            setLoading(true);
            if (id) {
                const payloadUpdate = {
                    name,
                    description
                };
                response = await apiClient.patch(
                    `/contracts/${numCont}/companies/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate(-1);
                }
            } else {
                const payload = {
                    name,
                    description,
                    ruc,
                    sequence
                };
                response = await apiClient.post(`/contracts/${numCont}/companies`, payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate(-1);
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("USERNAME_ALREADY_EXIST")) {
                setErrorMessage("La Compania ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrio un error al crear la Compania");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }finally{
            setLoading(false);
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Companias" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información de la Compania</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.sequence ? "red" : "inherit" }}>
                            Secuencia
                        </label>
                        <input
                            type="text"
                            placeholder="Secuencia"
                            value={sequence}
                            onChange={(e) => setSequence(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.sequence && <p className="error-message">{errors.sequence}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.ruc ? "red" : "inherit" }}>
                            Ruc
                        </label>
                        <input
                            type="text"
                            placeholder="RUC"
                            value={ruc}
                            onChange={(e) => setRuc(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.ruc && <p className="error-message">{errors.ruc}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.name ? "red" : "inherit" }}>
                            Nombre
                        </label>
                        <input
                            type="text"
                            placeholder="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.description ? "red" : "inherit" }}>
                            Descripción
                        </label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        {errors.description && <p className="error-message">{errors.description}</p>}
                    </div>

                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "La Compania se ha Actualizado Correctamente."
                        : "La Compania se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />
            {loading && <Loader />}
        </div>
    );
};

export default CompaniesForm;
