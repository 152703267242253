import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import "./PurchaseSummary.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import WarningNotification from '../../components/Notifications/WarningNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import SuccessProcess from '../../components/Notifications/SuccessProcess';
import { faCircleArrowLeft, faDollarSign, faCheck, faBasketShopping, faXmark } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import Section from '../../components/Section';
import apiClient from "../../axios";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from "../../components/Loader";

const PurchaseSummary = ({ handleLogout }) => {
    const { orderNumber } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isWarnningVisible, , setIsWarnningVisible,] = useState(false);
    const navigate = useNavigate();
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [isSuccessProcessVisible, setIsSuccessProcessVisible] = useState(false);
    const [file, setFile] = useState(null);
    const token = localStorage.getItem('token');
    const miBaseURL = apiClient.defaults.baseURL;
    const [mensage, setMensaje] = useState("");
    const [errors, setErrors] = useState({});
    const [orderData, setOrderData] = useState({
        numSerie: "",
        cliente: "",
        servidor: "",
        tipocontra: "",
        contEmpre: "",
        observacion: "",
        tipoContrato: "",
        distribuidor: ""
    });
    const [saleData, setSaleData] = useState({
        total: 0,
        subtotal: 0,
        taxPercentage: 0,
        taxes: 0
    });

    const handleCloseSuccess = () => {
        setIsSuccessProcessVisible(false);
    };

    useEffect(() => {
        // Función para cargar datos de la orden
        const fetchOrderData = async () => {
            try {
                const response = await apiClient.get(`/orders/${orderNumber}`);
                const data = response.data.contractData;
                setOrderData({
                    numSerie: data.numSerie || '',
                    cliente: data.cliente || '',
                    servidor: data.servidor || '',
                    tipocontra: data.productType || '',
                    contEmpre: data.contEmpre || '',
                    observacion: data.observacion || '',
                    tipoContrato: data.contractType || '',
                });
                const dataSale = response.data;
                setSaleData({
                    total: dataSale.total || 0,
                    subtotal: dataSale.subtotal || 0,
                    taxPercentage: dataSale.taxPercentage || 0,
                    taxes: dataSale.taxes || 0,
                });
            } catch (error) {
                console.error("Error al obtener los datos de la orden:", error);
            }
        };

        fetchOrderData();
    }, [orderNumber]); // Agregado orderNumber como dependencia

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/orders/${orderNumber}`);
            const orderItems = response.data.orderItems;
            if (Array.isArray(orderItems)) {
                setData(orderItems);
                setTotalItems(orderItems.length);
            }
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }
        } finally {
            setLoading(false);
        }
    }, [orderNumber]); // Agregado orderNumber como dependencia

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage, fetchAllData]); // Agregado fetchAllData como dependencia

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    const validateFields = () => {
        let validationErrors = {};
        if (!file) {
            validationErrors.file = "El Campo es Obligatorio";
        } else {
            const validTypes = ["application/pdf", "image/png", "image/jpeg"];
            if (!validTypes.includes(file.type)) {
                validationErrors.file = "El archivo debe ser un PDF, PNG, JPG o JPEG";
            }
        }

        return validationErrors;
    };

    // Maneja el cambio del archivo
    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Guarda el archivo en el estado
    };

    const handleConfirm = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            let response;
            if (orderData.tipoContrato === "Contador") {
                const validationErrors = validateFields();
                if (Object.keys(validationErrors).length > 0) {
                    setErrors(validationErrors);
                    setIsWarnningVisible(true);
                    return;
                }
                const formData = new FormData();
                formData.append('voucherFile', file);
                response = await fetch(`${miBaseURL}/orders/${orderNumber}/purchase`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Agrega el token de autenticación
                    },
                    body: formData,
                });


            } else {
                response = await apiClient.post(`/orders/${orderNumber}/purchase`);
            }

            if (response.status === 200) {
                if (orderData.tipoContrato === "Contador") {
                    setMensaje("El contrato se ha creado correctamente. La Orden esta pediente de aprobar")
                }
                else {
                    const newContractNumber = response.data?.contract;
                    const newInvoiceNumber = response.data?.purchaseData?.invoiceNumber;
                    setMensaje(`El contrato se ha creado correctamente con Número de Contrato ${newContractNumber} y Factura Número ${newInvoiceNumber}.`)
                }
                setIsErrorVisible(false);
                setIsSuccessVisible(true);
                setIsSuccessProcessVisible(true);
            }
            else if (response.status === 400) {
                setIsErrorVisible(true);
                //if (response.data.message.startsWith("FOR THIS CONTRACT TYPE CLIENT MUST BE ACCOUNTANT CLIENT CODE:")) {
                setMensaje("El cliente seleccionado no tiene configuración válida para registar contratos de tipo Contador.");
                setIsSuccessProcessVisible(true);
            }
        } catch (error) {


        }
        finally {
            setLoading(false);
        }
    };


    const columns = [
        { title: "Código", key: "code" },
        { title: "Descripción", key: "description" },
        { title: "Precio Modulo", key: "purchasePrice" },
        { title: "Suscripcones", key: "subscriptions" },
        { title: "Suscripciones Extras", key: "extraSubscriptions" },
        { title: "Descuento", key: "dicount" },
        { title: "Total", key: "total" }
    ];

    const renderRow = (item, index) => {
        const subtotal = item.extraSubscriptions * item.subscriptionPrice + item.purchasePrice;
        const total = subtotal - (subtotal * (item.discount / 100));

        return (
            <>
                <td>{item.code}</td>
                <td>{item.description}</td>
                <td className="align-right">${item.purchasePrice.toFixed(2)}</td>
                <td className="align-center">{item.subscriptions}</td>
                <td className="align-center">
                    {item.extraSubscriptions}
                    <div className="align-center">${item.subscriptionPrice.toFixed(2)} c/u</div>
                </td>
                <td className="align-right">{item.discount}%</td>
                <td className="align-right">${total.toFixed(2)}</td>
            </>
        )

    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title='Clientes' />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/Customers");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Resumen de la Orden</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label>Cliente</label>
                        <input
                            type="text"
                            placeholder="Cliente"
                            value={orderData.cliente}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Servidor</label>
                        <input
                            type="text"
                            placeholder="Servidor"
                            value={orderData.servidor}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label> Nro. Identificador</label>
                        <input
                            type="text"
                            placeholder=" Nro. Identificador"
                            value={orderData.numSerie}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label> Producto</label>
                        <input
                            type="text"
                            placeholder="Producto"
                            value={orderData.tipocontra}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Tipo Contrato</label>
                        <input
                            type="text"
                            placeholder="Tipo Contrato"
                            value={orderData.tipoContrato}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Observación</label>
                        <input
                            type="text"
                            placeholder="Observación"
                            value={orderData.observacion}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Descripción</label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={orderData.contEmpre}
                            readOnly
                        />
                    </div>
                    {orderData.tipoContrato === "Contador" && (
                        <div className="basic-info-form-group">
                            <label style={{ color: errors.file ? "red" : "inherit" }} htmlFor="file">
                                Subir Factura
                            </label>
                            <input type="file" onChange={handleFileChange} />
                            <p className="error-message">{errors.file}</p>
                        </div>
                    )}
                </div>
                <div className="basic-info-form-group">
                    <Table
                        title="Lista de Productos"
                        rows={data}
                        columns={columns}
                        icon={faBasketShopping}
                        renderRow={renderRow}
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        onPageChange={setCurrentPage}
                        onRefresh={handleRefresh}
                        loading={loading}
                    />
                </div>
                <div className="basic-form-footer">
                    <div className="basic-footer-container-group ">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faDollarSign} className="basic-shortcut-icon" />
                            <h3 className="modal-title">Resumen</h3>
                        </div>
                        <table style={{ borderCollapse: 'collapse', width: '30%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Subtotal:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>${saleData.subtotal.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Porcentaje IVA:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>{saleData.taxPercentage.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Valor IVA:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>${saleData.taxes.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Total:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>${saleData.total.toFixed(2)}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/Customers");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button className="basic-custom-button" onClick={handleConfirm}>
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="basic-shortcut-icon"
                        />
                        Confirmar
                    </button>
                </div>
            </div>
            {loading && <Loader />}
            {isSuccessVisible && (
                <SuccessNotification
                    message="La compra se realizó con éxito."
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
            )}
            {isErrorVisible && (
                <ErrorNotification
                    message="Error al realizar la compra."
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
            )}
            {isWarnningVisible && (
                <WarningNotification
                    message="Existe algun error con el archivo de Factura."
                    isVisible={isWarnningVisible}
                    onClose={() => setIsWarnningVisible(false)}
                />
            )}
            {isLoginVisible && (
                <LoginNotification
                    message="La sesión ha expirado."
                    isVisible={isLoginVisible}
                    onClose={() => setIsLoginVisible(false)}
                />
            )}
            {isSuccessProcessVisible && (
                <SuccessProcess
                    message={mensage}
                    isVisible={isSuccessProcessVisible}
                    onClose={handleCloseSuccess}
                    redirectOnOk={true}
                    pageNavigate={"/Customers"}
                />
            )}
        </div>
    );
};

export default PurchaseSummary;
