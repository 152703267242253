import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import "./ModuleContract.css";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
  faSave,
  faRotate,
  faCircleArrowLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";
import Select from "react-select";

const ModuleContract = ({ handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const moduleId = searchParams.get("moduleId");

  const [distributors, setDistributors] = useState([]);

  const [numContId, setContId] = useState(
    moduleId ? '' : location.state?.modules?.numCont || "");
  const [modulo, setCodigo] = useState("");
  const [numLicencias, setNumLicencias] = useState(1);
  const [fechaFin, setFechaFin] = useState(null);
  const [activo, setIsActive] = useState(0);
  const [motivo, setMotivo] = useState("");
  const [isPay, setIsPay] = useState(0);
  const [maxCount, setMaxCount] = useState("");
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const role = localStorage.getItem('role');
  const distributorToken = localStorage.getItem('distributor');
  const [canal, setCanal] = useState(role !== 'SUPER_ADMIN' && role !== 'ADMIN' ? distributorToken : distributorToken);
  const [products, setProducts] = useState([]);

  const toggleSwitch = () => {
    setIsActive((prev) => !prev);
  };

  const validateFields = () => {
    let validationErrors = {};
    if (!numContId) validationErrors.numContId = "El Campo es Obligatorio";
    if (!modulo) validationErrors.modulo = "El Campo es Obligatorio";
    if (!numLicencias) validationErrors.numLicencias = "El Campo es Obligatorio";
    if (!canal) validationErrors.canal = "El Campo es Obligatorio";
    if (!fechaFin) validationErrors.fechaFin = "El Campo es Obligatorio";
    if (!isPay) validationErrors.isPay = "El Campo es Obligatorio";
    if (!maxCount) validationErrors.maxCount = "El Campo es Obligatorio";
    return validationErrors;
  };


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await apiClient.get("/products");
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);


  useEffect(() => {
    const fetchDistributors = async () => {
      try {
        const response = await apiClient.get("/distributors");
        setDistributors(response.data);
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    };

    //fetchData(); // Assuming fetchData is defined elsewhere and stable
    fetchDistributors();
  }, []); // Add 'fetchData' to the dependency array


  const handleClick = () => {
    navigate(-1);
  };

  const handleDateChange = (date) => {
    // Convierte la fecha seleccionada al formato YYYY-MM-DD HH:mm:ss
    const formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
    setFechaFin(formattedDate);
  };

  useEffect(() => {
    if (moduleId) {
      const fetchModule = async () => {
        try {
          const response = await apiClient.get(`/modules/${moduleId}`);
          const fechaFin = response.data.fechaFin
            ? new Date(response.data.fechaFin)
            : null;
          setContId(response.data.numContId);
          setCodigo(response.data.modulo);
          setNumLicencias(response.data.numLicencias);
          setCanal(response.data.canal);
          setFechaFin(fechaFin);
          setIsActive(response.data.activo === 1 ? true : false);
          setMotivo(response.data.motivo);
          setIsPay(response.data.isPay === 1 ? "Pagado" : "Gratis");
          setMaxCount(response.data.maxCount);
        } catch (error) {
          console.error("Error al cargar el modulo", error);
          if (error.response && error.response.status === 401) {
            setIsInfoVisible(true);
          }
        }
      };
      fetchModule();
    } else if (numContId) {
      setContId(numContId);
    }
  }, [moduleId, numContId]); // Add numContId to the dependency array


  const handleModuleChange = useCallback((e) => {
    const selectedProductCode = e.target.value;
    setCodigo(selectedProductCode);
  }, []);

  const handleDistributorChange = (selectedOption) => {
    setCanal(selectedOption ? selectedOption.value : "");
  };
  const handleSave = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      numContId,
      modulo,
      canal,
      num_licencias: numLicencias,
      is_pay: isPay === "Pagado" ? true : false,
      max_count: maxCount,
      fechaFin
    };
    const payloadUpdate = {
      isPay: isPay === "Pagado" ? true : false,
      maxCount: maxCount,
      activo: activo === false ? false : true,
      motivo,
      fechaFin,
      numLicencias: numLicencias,
      canal
    };
    try {
      if (moduleId) {
        // Modo edición: usar PATCH
        await apiClient.patch(
          `/modules/edit_by_contract/${numContId}/${modulo}`,
          payloadUpdate
        );
      } else {
        // Modo creación: usar POST
        await apiClient.post("/modules", payload);
      }
      setIsSuccessVisible(true);
      setIsErrorVisible(false);
      navigate(-1);
    } catch (error) {
      setIsErrorVisible(true);
      setIsSuccessVisible(false);
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 4000);
    }
  };
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setIsPay(value);
    if (value === 'Pagado') {
      setMaxCount(999);
    } else if (value === 'Gratis') {
      setMaxCount(25);
    } else {
      setMaxCount(0); // Si ninguna opción está seleccionada
    }
  };
  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title="Módulo por Contrato" />
      <Section>
        <div className="button-return-container">
          <FontAwesomeIcon
            className="basic-shortcut-icon"
            style={{ cursor: "pointer" }}
            icon={faCircleArrowLeft}
            onClick={handleClick}
          />
        </div>
      </Section>
      <div className="moduleNew-form-container">
        <h3 className="basic-info-form-title">Información del Módulo</h3>
        <div className="basic-info-form-grid">
          <div className="basic-info-form-group">
            <label style={{ color: errors.numContId ? "red" : "inherit" }}>Número de Contrato</label>
            <input
              type="text"
              placeholder="Contrato"
              value={numContId}
              onChange={(e) => setContId(e.target.value)}
              readOnly
            />
            {errors.numContId && <p className="error-message">{errors.numContId}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.modulo ? "red" : "inherit" }}>Módulo</label>
            <select
              value={modulo}
              onChange={handleModuleChange}
            >
              <option value="">Seleccione un módulo</option>
              {products.map((product) => (
                <option key={product.codigo} value={product.codigo}>
                  {product.descripcion}
                </option>
              ))}
            </select>
            {errors.modulo && <p className="error-message">{errors.modulo}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.numLicencias ? "red" : "inherit" }}>Número de Licencias</label>
            <input
              type="text"
              placeholder="Número de Licencias"
              value={numLicencias}
              onChange={(e) => setNumLicencias(e.target.value)}
            />
            {errors.numLicencias && <p className="error-message">{errors.numLicencias}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.canal ? "red" : "inherit" }} >Distribuidor</label>
            <Select
              value={
                canal
                  ? {
                    value: canal,
                    label: distributors.find(
                      (distributor) => distributor.code === canal
                    )?.name,
                  }
                  : null
              }
              onChange={handleDistributorChange}
              options={distributors.map((distributor) => ({
                value: distributor.code,
                label: distributor.name,
              }))}
              placeholder="Seleccione un Distribuidor"
              isClearable // Para permitir limpiar la selección
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "600px", // Cambia el ancho a lo que desees
                }),
                control: (provided) => ({
                  ...provided,
                  textAlign: "left", // Alinear el texto a la izquierda
                  fontFamily: "Poppins, sans-serif", // Cambiar la fuente
                  fontSize: "12px", // Cambiar el tamaño de fuente
                }),
                placeholder: (provided) => ({
                  ...provided,
                  textAlign: "left", // Alinear el placeholder a la izquierda
                  fontFamily: "Poppins, sans-serif", // Cambiar la fuente del placeholder
                  fontSize: "12px", // Cambiar el tamaño de la fuente del placeholder
                }),
                menu: (provided) => ({
                  ...provided,
                  textAlign: "left", // Alinear el menú a la izquierda
                }),
                option: (provided, state) => ({
                  ...provided,
                  textAlign: "left", // Alinear las opciones a la izquierda
                  backgroundColor: state.isSelected ? '#e0e0e0' : 'white', // Color de fondo si está seleccionada
                  color: state.isSelected ? 'black' : 'black', // Color del texto
                }),
              }}
            />
            {errors.canal && <p className="error-message">{errors.canal}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.fechaFin ? "red" : "inherit" }}>Caduca</label>
            <DatePicker
              selected={fechaFin ? new Date(fechaFin) : null}
              onChange={handleDateChange}
              showTimeSelect
              locale={es}
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Selecciona la Fecha"
              className="custom-date-picker"
            />
            {errors.fechaFin && <p className="error-message">{errors.fechaFin}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.isPay ? "red" : "inherit" }}>Plan</label>
            <select value={isPay} onChange={handleSelectChange}>
              <option value="">Seleccione una opción</option>
              <option value="Pagado">Pagado</option>
              <option value="Gratis">Gratis</option>
            </select>
            {errors.isPay && <p className="error-message">{errors.isPay}</p>}
          </div>
          <div className="basic-info-form-group">
            <label style={{ color: errors.maxCount ? "red" : "inherit" }}>Número Máximo de Registros</label>
            <input
              type="number"
              placeholder="Número Máximo de Registros"
              value={maxCount}
              onChange={(e) => setMaxCount(e.target.value)}
            />
            {errors.maxCount && <p className="error-message">{errors.maxCount}</p>}
          </div>
        </div>
        <div className="basic-info-form-grid">
          {/* Solo renderizar si moduleId no es vacío */}
          {moduleId && (
            <div className="basic-info-form-group4">
              <label>Estado</label>
              <div className="slider-container" onClick={toggleSwitch}>
                <div className={`slider-option ${activo ? "active" : "inactive"}`}>
                  Activo
                </div>
                <div className={`slider-option ${!activo ? "active" : "inactive"}`}>
                  Inactivo
                </div>
              </div>
            </div>
          )}

          {/* Solo renderizar si moduleId no es vacío y activo es false */}
          {moduleId && !activo && (
            <div className="basic-info-form-group">
              <label>Motivo</label>
              <input
                type="text"
                placeholder="Motivo"
                value={motivo}
                onChange={(e) => setMotivo(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="basic-form-footer">
          <button
            className="basic-custom-button"
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
            Cancelar
          </button>
          <button className="basic-custom-button" onClick={handleSave}>
            <FontAwesomeIcon
              icon={moduleId ? faRotate : faSave}
              className="basic-shortcut-icon"
            />
            {moduleId ? "Actualizar" : "Guardar"}
          </button>
        </div>
      </div>
      <SuccessNotification
        message={
          moduleId
            ? "El Módulo se ha Actualizado Correctamente."
            : "El Módulo se ha Creado Correctamente."
        }
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
      />
      {isErrorVisible && (
        <ErrorNotification
          message="Hubo un problema al Crear el Módulo. Inténtalo de nuevo."
          isVisible={isErrorVisible}
          onClose={() => setIsErrorVisible(false)}
        />
      )}
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isInfoVisible}
        onClose={() => setIsInfoVisible(false)}
        onLogout={handleLogout}
      />
    </div>
  );
};

export default ModuleContract;
